import React from 'react'
import { DateTime } from 'luxon'
import { Link, navigate } from 'gatsby'
import { Modal, CircularProgress, ReportTable, Icon, Alert, CardCollapse, Spacer, Typography, ScoreScale } from '../components/library'
import { parseSpirometerItem } from '../utils/parseSpirometerReports'
import hexToRgbA from '../utils/hexToRgbA'
import { Container, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  legend: {
    display: 'flex',
    '& > *:first-child': {
      paddingRight: theme.spacing(2)
    }
  }
}))

function spirometryDetailView({ location }) {
  const classes = useStyles()
  let response = typeof location !== 'undefined' && location && location.state ? location.state.response : null
  let backTo = typeof location !== 'undefined' && location && location.state?.backTo ? location.state.backTo : -1
  if (!response) return <CircularProgress />
  const report = parseSpirometerItem(response)
  if (!report)
    return (
      <Container maxWidth="sm">
        <Spacer />
        <Alert icon={<Icon name="cancel" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="sm" />} color="red" size="sm">
          <span>Error while parsing spirometer data </span>
          <Link to="/" style={{ color: 'white' }}>
            Go back
          </Link>
        </Alert>
      </Container>
    )

  const table_data = {
    header: ['FEV1', 'FVC', 'FEV1/FVC'],
    rows: [
      {
        label: 'Predicted %',
        cells: {
          FEV1: { value: `${report.fev1.predicted}%`, level: report.fev1.level },
          FVC: { value: `${report.fvc.predicted}%`, level: report.fvc.level },
          'FEV1/FVC': { value: `${report.fev1_fvc.predicted}%`, level: report.fev1_fvc.level }
          // 'FEV1': {value: `${report.fev1.predicted}%`, level: report.fev1.predicted_level},
          // 'FVC': {value: `${report.fvc.predicted}%`, level: report.fvc.predicted_level},
          // 'FEV1/FVC': {value: report.fev1_fvc.predicted, level: report.fev1_fvc.level},
        }
      }
    ]
  }
  return (
    // <ReportsWrapper currentTab={1}>
    <Modal
      id="spirometry-detail-modal"
      open={true}
      title="Spirometry report"
      subtitle={`${report.date.toLocaleString(DateTime.DATE_FULL)} ${report.date.toLocaleString(DateTime.TIME_SIMPLE)}`}
      handleClose={e => {
        navigate(backTo)
      }}
    >
      <ReportTable data={table_data} />
      <CardCollapse title="Legend">
        <div className={classes.legend}>
          <Typography variant="body1" shade="dark">
            <strong>FEV1</strong>
          </Typography>
          <Typography variant="body1">
            is the amount of air a person can forcefully exhale in 1 second. Higher percentages indicate better asthma control.
          </Typography>
        </div>
        <ScoreScale
          levels={[
            { value: 80, label: '0-80% poorly controlled', level: 0 },
            { value: 160, label: '>=80% well controlled', level: 4 }
          ]}
          hideLastNum
        />
        <Spacer />
        <div className={classes.legend}>
          <Typography variant="body1" shade="dark">
            <strong>FEV1/FVC</strong>
          </Typography>
          <Typography variant="body1">
            is the proportion of air a person can exhale in 1 second to their full lung capacity. Higher percentages indicate better asthma
            control.
          </Typography>
        </div>
        <ScoreScale
          levels={[
            { value: 75, label: '0-75% poorly controlled', level: 0 },
            { value: 80, label: '75-80% partially controlled', level: 1 },
            { value: 130, label: '>=80% well controlled', level: 4 }
          ]}
          hideLastNum
        />
      </CardCollapse>
    </Modal>
    // </ReportsWrapper>
  )
}

export default spirometryDetailView
